body {
  margin: 0;
  font-family: "Noto Sans";
  font-size: 14px !important;
  /* background-color: #252A37 !important; */
}

:root {
  --primary: #287FFF;
  --gray: #838283;
  --background: #E9F2FF;
}

input::-webkit-input-placeholder {
  font-size: 14px;
}

input {
  padding: 7px 0px 7px 3px !important;
  font-size: 14px !important;
}

input:focus {
  border-color: #ced4da !important;
}

.light-text {
  color: var(--gray);
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem !important;
}

img {
  width: 100%;
}


.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(33, 38, 49, 0.5);
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #0d6efd #0d6efd transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.ql-container {
  border: none !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}