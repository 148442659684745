.purpity-logo-sidebar {
  background-color: #212631;
  color: #fff;
  font-size: 28px;
  margin-bottom: 20px;
}

.showVideos {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFound {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.goBack {
  margin: 50px;
  border: 1px solid lightgrey;
  padding: 10px 20px;
  border-radius: 20px;
}

#wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #252a37;
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: #212631;
  border: 1px solid #49566d;
}

.activeLink {
  background: #49566d;
}

.sidebar {
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #212631;
  /* Firefox */

  /* WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 10px;
    /* Set the width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #49566d;
    /* Set the color of the scrollbar thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #212631;
    /* Set the color of the scrollbar track */
  }
}

#wrapper.toggled #sidebar-wrapper {
  width: 300px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  background-color: #252a37;
  max-width: 1500px;
  margin: auto 30px;
  min-height: 100vh;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #fff;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  text-align: center;
  font-size: 18px;
}

.sidebar-nav>.sidebar-brand a {
  color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.sidebar-links {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

.sidebar-links>p {
  margin-bottom: 0px;
}

.homeCard {
  background-color: #212631 !important;
  color: #fff !important;
  border: 1px solid #49566d !important;
}

.deposit-container,
.withdraw-container,
.upload-form-container,
.deposit-table,
.withdraw-table {
  background-color: #212631 !important;
  color: #fff !important;
  border: 1px solid #49566d !important;
}

.deposit-table th,
.withdraw-table th {
  background-color: #212631 !important;
  color: #fff !important;
  border: 1px solid #49566d !important;
}

.deposit-table td,
.withdraw-table td {
  background-color: #212631 !important;
  color: #fff !important;
  border: 1px solid #49566d !important;
}

.deposit-table caption {
  color: #fff !important;
  padding: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  color: #fff;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.fileUploadForm {
  width: 50%;
}

.fileList {
  color: #fff;
  margin-top: 20px;
}

.react-international-phone-input {
  width: 100%;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }

  #wrapper.toggled {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 300px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}