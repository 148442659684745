.input-icon {
    background-color: #fff !important;
    height: 100%;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right:none !important;
}

.input-style {
    border-left: none !important;
}
