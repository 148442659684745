@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&family=Roboto&display=swap');
 html,body{ 
    margin: 0px; 
    padding: 0px;
    box-sizing: border-box;
    overflow-x: hidden;
 }
 
.deleted{
   color: red !important;
   letter-spacing: 2px;
}